<template>
  <div class="vistor">
    <div class="head">
      <div class="detail">
        <div class="list">
          <!-- 不需要主动绑定微信功能 -->
          <!-- <p class="title">
            <span class="w72">绑定微信</span>
            <span class="binding">已绑定</span>
            <img class="icon" src="../../assets/img/login/icon_arrow_right.png">
          </p> -->
          <p class="title" @click="resetClick">
            <span class="w72">修改密码</span>
            <img class="icon" src="~@/assets/img/login/icon_arrow_right.png" />
          </p>
          <template v-if="type === 'org'">
            <p class="title" @click="demand">
              <span class="w72">项目需求</span>
              <img class="icon" src="~@/assets/img/login/icon_arrow_right.png" />
            </p>
            <p class="title none" @click="feedback">
              <span class="w72">意见反馈</span>
              <img class="icon" src="~@/assets/img/login/icon_arrow_right.png" />
            </p>
          </template>
        </div>
        <!-- <div style="text-align:center;font-size:10"> 版本号 1.1 </div> -->
        <div class="button" @click="logout">退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      realName: ''
    }
  },
  computed: {
    ...mapGetters(['type'])
  },
  methods: {
    // 修改密码
    resetClick() {
      this.$router.push({
        path: '/settings/restpwdsms'
      })
    },
    // 关于我们
    template() {
      this.$router.push({
        path: '/template',
        query: { code: 'mobile_about_us' }
      })
    },
    demand() {
      this.$router.push('/admin/project')
    },
    feedback() {
      // this.$router.push('/feedback')
      this.$router.push('/feedback/list')
    },
    // 退出登录
    logout() {
      const _this = this
      localStorage.removeItem('data')
      store.dispatch('Logout').finally(() => {
        setTimeout(function () {
          _this.$router.replace({
            name: 'login'
          })
        }, 500)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.vistor {
  overflow: auto;
  padding-bottom: 50px;
}

.head {
  width: 100%;

  p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 36px;
  }
}

.detail {
  .list {
    background: #fff;
    padding: 0 15px;
    margin-bottom: 10px;

    .title {
      color: #666;
      padding-bottom: 20px;
      border-bottom: 1px solid #f0f0f0;
      font-size: 15px;
      margin-bottom: 0;
      padding-top: 20px;
      position: relative;

      .w72 {
        text-align: left;
        display: inline-block;
      }

      .binding {
        float: right;
        background: #f2f2f2;
        padding: 3px 7px;
        color: #666;
        font-size: 12px;
        border-radius: 10px;
        margin-right: 20px;
      }

      img {
        position: absolute;
        top: 25px;
        right: 2px;
      }
    }

    .none {
      border: none;
    }
  }

  .button {
    margin-top: 10px;
    padding: 20px 0;
    background: #fff;
    color: #f94c17;
    text-align: center;
  }
}
</style>
